
<template>
    <v-container>
        <v-row dense >
            <v-col v-for="(item, i) in items" :key="i" cols="12" md="4" sm="6">
                <v-card :color="item.color" dark
                :to="permiso != 'ROLE_USER' ? item.to : ''">
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title class="headline text-h6" v-text="item.title"></v-card-title>
                            <v-card-subtitle v-text="item.description"></v-card-subtitle>
                            <v-card-actions> 
                                <v-btn color="orange lighten-2" class="ml-2 mt-3" fab icon height="40px" right width="40px">
                                    <v-icon>{{item.icon}}</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </div>
                        <v-avatar class="ma-1" size="125" tile>
                            <v-img :src="item.src" contain></v-img>
                        </v-avatar>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <GraficaVenta></GraficaVenta>
    </v-container>
</template>
<script>
import GraficaVenta from '../graficas/GraficaVenta.vue';
    export default {
        name: 'Dashboard',
        components:{
            GraficaVenta
        },
        data() {
            return {
                items: [
                    {
                        color:      '#1F7087',
                        src:        `${this.$store.state.auth.rutaImg}pollo_blanco.png`,
                        title:      'Compras',
                        description:'Nuevas mercancias',
                        icon:       'fas fa-shopping-cart',
                        to:         '/controlPanel/entradas'    
                    },
                    {
                        color:      '#952175',
                        src:        `${this.$store.state.auth.rutaImg}procesado.png`,
                        title:      'Ventas',
                        description:'Ventas de productos',
                        icon:       'fas fa-cash-register',
                        to:         '/controlPanel/ventas'
                    },
                ],
                rol: "",
            }
        },
        computed: {
            permiso(){
                let roles =  this.$store.state.auth.user.roles;
                if( roles.length > 0 ){
                    return roles[0]
                }
            },  
        },
    }
</script>