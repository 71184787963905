<template>
    <highcharts
        :options="chartOptionsMerma"
        style="height: 320px; width: 100%;"
    />
</template>
<script>
import { Chart } from 'highcharts-vue';
import VentaDetalleDataService from '@/service/venta/VentaDetalleDataService';
export default {
    name: "GraficaMerma",
    components: {
        highcharts: Chart 
    },
    created(){
        this.grafica();
    },
    data() {
        return {
            chartOptionsMerma: {
                chart: { type: 'column' },
                title: { text: 'Merma (Pzas)' },
                xAxis: {
                    categories: [],
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: { text: 'Cantidad' }
                },
                legend: { reversed: true },
                tooltip: {
                    headerFormat: '<span style="font-size:14px;font-weight:bold;">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:1;font-weight:bold;">{series.name}: </td>' +
                        '<td style="padding:0;font-size:15px; font-weight:bold;"><b>{point.y:.0f} piezas</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: []
            }
        }
    },
    methods: {
        grafica(){
            VentaDetalleDataService.graficaVenta(2).then( resp => {
                const products = resp.data;
                const categoryByProduct = products.map( item => item.producto );
                const categories = [...new Set(categoryByProduct)];
                let groupedData = {};
                products.forEach( item => {
                    if(!groupedData[item.day] ){
                        groupedData[item.day] = {}
                    }
                    if(!groupedData[item.day][item.producto] ){
                        groupedData[item.day][item.producto] = 0;
                    }
                    groupedData[item.day][item.producto] += item.cantidad;
                })
                let series = [];
                Object.entries(groupedData).forEach(([day, products]) => {
                    const cantidad = Object.values(products);
                    series.push({ name: day, data: cantidad });
                });
                series.reverse();
                this.chartOptionsMerma.series.push(...series);
                this.chartOptionsMerma.xAxis.categories = categories;
            })
        },
    }
}</script>
</script>