<template>
    <div>
        <v-subheader>
            <v-icon large color="blue-grey darken-2">fas fa-chart-bar</v-icon>
            <span class="text-bold ml-2 font-weight-bold text-h6">Estadisticas</span>
        </v-subheader>
        <v-divider></v-divider>
        <v-row>
            <v-col cols="12" md="12" xs="12">
                <v-card color="#385F73" dark>
                    <v-card-text>
                        <highcharts :options="chartOptionsVenta" style="width: 100%; height: 300px;">
                        </highcharts>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="12" xs="12">
                <v-card color="#385F73" dark>
                    <v-card-text>
                        <GraficaMerma></GraficaMerma>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { Chart } from 'highcharts-vue';
import VentaDetalleDataService from '@/service/venta/VentaDetalleDataService';
import GraficaMerma from './GraficaMerma.vue'
export default {
    name: "GraficaVenta",
    components: {
        highcharts: Chart,
        GraficaMerma
    },
    created(){
        this.grafica();
    },
    data() {
        return {
            chartOptionsVenta: {
                chart: { type: 'column'},
                title: { text: 'Venta (Pzas)' },
                xAxis: {
                    categories: [],
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: { text: 'Cantidad' }
                },
                legend: { reversed: true },
                tooltip: {
                    headerFormat: '<span style="font-size:14px;font-weight:bold;">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:1;font-weight:bold;">{series.name}: </td>' +
                        '<td style="padding:0;font-size:15px; font-weight:bold;"><b>{point.y:.0f} piezas</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: []
            },
        }
    },
    methods: {
        grafica(){
            VentaDetalleDataService.graficaVenta(1).then( resp => {
                const products = resp.data;
                const categoryByProduct = products.map( item => item.producto );
                const categories = [...new Set(categoryByProduct)];
                let groupedData = {};
                products.forEach( item => {
                    if(!groupedData[item.day] ){
                        groupedData[item.day] = {}
                    }
                    if(!groupedData[item.day][item.producto] ){
                        groupedData[item.day][item.producto] = 0;
                    }
                    groupedData[item.day][item.producto] += item.cantidad;
                })
                let series = [];
                Object.entries(groupedData).forEach(([day, products]) => {
                    const cantidad = Object.values(products);
                    series.push({ name: day, data: cantidad });
                });
                series.reverse();
                this.chartOptionsVenta.series.push(...series);                
                this.chartOptionsVenta.xAxis.categories = categories;
            })
        },
    }
}
</script>